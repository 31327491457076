<template>
	<div class="smartphone-device">
		<div class="content">
			<iframe id="SmartPhonePreview" style="width:100%;border:none;height:100%" />
		</div>
	</div>
</template>
<script>
	
	export default {
		name: 'SmartPhone',
		props: {
			content: {}
		},
		components: {
			
		},
		data() {
			return {
				
			}
		},
		computed : {
			
		},
		watch: {
			content(TextContent) {
				var doc = document.getElementById('SmartPhonePreview').contentWindow.document
				doc.open()
				doc.write(TextContent)
				doc.close()
			}
		},
		mounted() {
			let TextContent = this.content
			var doc 		= document.getElementById('SmartPhonePreview').contentWindow.document
			doc.open()
			doc.write(TextContent)
			doc.close()
		},
		methods: {
			
		}
	}
</script>
<style lang="scss">
	.smartphone-device {
		/* The device with borders */
		position: relative;
		width: 360px;
		height: 640px;
		margin: auto;
		border: 16px black solid;
		border-top-width: 60px;
		border-bottom-width: 60px;
		border-radius: 36px;
		
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
		-webkit-transform: scale(0.7) translate(15%);
		transform: scale(0.7) translate(15%);
		
		/* The horizontal line on the top of the device */
		&:before {
			content: '';
			display: block;
			width: 60px;
			height: 5px;
			position: absolute;
			top: -30px;
			left: 50%;
			transform: translate(-50%, -50%);
			background: #333;
			border-radius: 10px;
		}
		
		/* The circle on the bottom of the device */
		&:after {
			content: '';
			display: block;
			width: 35px;
			height: 35px;
			position: absolute;
			left: 50%;
			bottom: -65px;
			transform: translate(-50%, -50%);
			background: #333;
			border-radius: 50%;
		}
		
		/* The screen (or content) of the device */
		.content {
			width: 100%; /* 360px */
			height: 520px; /* 640px */
			background: white;
		}
		
	}
</style>
