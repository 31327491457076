<template>
	<div class="laptop-device">
		<div class="content">
			<iframe id="LaptopPreview" style="width:100%;border:none;height:100%" />
		</div>
	</div>
</template>
<script>
	
	export default {
		name: 'Laptop',
		props: {
			content: {}
		},
		components: {
			
		},
		data() {
			return {
				
			}
		},
		watch: {
			content(TextContent) {
				var doc = document.getElementById('LaptopPreview').contentWindow.document
				doc.open()
				doc.write(TextContent)
				doc.close()
			}
		},
		computed : {
			
		},
		mounted() {
			let TextContent = this.content
			var doc 		= document.getElementById('LaptopPreview').contentWindow.document
			doc.open()
			doc.write(TextContent)
			doc.close()
		},
		methods: {
			
		}
	}
</script>
<style lang="scss">
	/* The laptop with borders */
	.laptop-device {
		// -webkit-transform-origin: 0 0;
		// transform-origin: 0 0;
		// -webkit-transform: scale(.6) translate(-50%); /* Scaled down for a better Try-it experience (change to 1 for full scale) */
		// transform: scale(.6) translate(-50%); /* Scaled down for a better Try-it experience (change to 1 for full scale) */
		// left: 50%;
		// position: absolute;
		
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
		-webkit-transform: scale(0.55) translate(0%);
		transform: scale(0.55) translate(0%);
		
		
		width: 1366px;
		height: 768px;
		border-radius: 6px;
		border-style: solid;
		border-color: black;
		border-width: 24px 24px 80px;
		background-color: #fff;
		
		
		/* The keyboard of the laptop */
		&:after {
			content: '';
			display: block;
			position: absolute;
			width: 1600px;
			height: 60px;
			margin: 80px 0 0 -110px;
			background: black;
			border-radius: 6px;
		}

		/* The top of the keyboard */
		&:before {
			content: '';
			display: block;
			position: absolute;
			width: 250px;
			height: 30px;
			bottom: -110px;
			left: 50%;
			-webkit-transform: translate(-50%);
			transform: translate(-50%);
			background: #f1f1f1;
			border-bottom-left-radius: 5px;
			border-bottom-right-radius: 5px;
			z-index: 1;
		}

		/* The screen (or content) of the device */
		.content {
			width: 100%; /* 1366px */
			height: 664px; /* 800px */
			overflow: hidden;
			border: none;
		}
	}
	
</style>
